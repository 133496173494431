jQuery(document).ready(function(){

  // Mobile Overlay

  jQuery('#toggle').click(function() {
    jQuery(this).toggleClass('active');
    jQuery('#overlay').toggleClass('open');

  });

   jQuery('#overlay a').click(function() {
    jQuery('#toggle').removeClass('active');
    jQuery('#overlay').removeClass('open');
   
  });

  // Smooth Scroll

  jQuery('.primary-nav a[href^="#"], #mobile-nav a[href^="#"]').on('click',function (e) {
    e.preventDefault();

    var target = this.hash;
    var $target = jQuery(target);

    jQuery('html, body').stop().animate({
      'scrollTop': $target.offset().top - 73
    },900, 'swing', function () {
      window.location.hash = target;
    });
    return false; 
  
  });

  // Fade and Hide Header on Scroll

  jQuery(window).scroll(function() {

    if (jQuery(window).width() > 960) {

      if (jQuery(this).scrollTop() > 100){  
        jQuery('header').addClass("fade");
      } else{
        jQuery('header').removeClass("fade");
      }

      if (jQuery(this).scrollTop() > 300){  
        jQuery('header').addClass("hide");
      } else{
        jQuery('header').removeClass("hide");
      }

    }
  
  });

});